import React from "react";
import loadable from "@loadable/component";
import webDev from "@images/WebDev.svg";
import WebDev1 from "@images/WebDev-1.svg";
import WebDev2 from "@images/WebDev-2.svg";
import WebDev3 from "@images/WebDev-3.svg";
import WebDev4 from "@images/WebDev-4.svg";
import WebDev5 from "@images/WebDev-5.svg";
import WebDev6 from "@images/WebDev-6.svg";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { OfferSectionStyle } from "../../../Styles/OfferSectionStyle";

const DropDownListSection = loadable(() =>
    import("@common/CommonSections/DropDownListSection/DropDownListSection")
);

const listItems = [
    {
        title: "End-to-end product development",
        iconPath: [webDev, null],
        description: `
            From the first get-to-know meeting to the maintenance and support — our team will stay
            with you at every stage of development. End to end web app development with Impressit 
            ensures the best experience for you and the best results for your business.
        `,
    },
    {
        title: "Software architecture design",
        iconPath: [WebDev1, null],
        description: `
            Before the product gets to the development stage, the software architecture should be created. 
            We take into consideration your goals and product requirements to create an enterprise software 
            architecture and ensure that the product will perform well, will include all the necessary features, 
            and will be generally successful. Software architecture and web application development
            with Impressit is the key to success.
        `,
    },
    {
        title: "UI/UX design",
        iconPath: [WebDev2, null],
        description: `
            We are keeping two main points in our minds: your business objectives and your customers’ comfort. 
            This allows us to create designs that would be not only attractive and recognizable 
            but also intuitive and engaging.
        `,
    },
    {
        title: "High-load web apps development",
        iconPath: [WebDev3, null],
        description: `
            If you hire web developer with Impressit, you should not worry if your system can cope with the load. 
            Our team has expertise in web app development that can handle the load easily with no crashes
            or errors so your customers are always satisfied.
        `,
    },
    {
        title: "ERP systems development",
        iconPath: [WebDev4, null],
        description: `
            The development of ERP systems changes daily business processes in every industry for the better. 
            That’s why the demand for custom ERP developers is as high as ever. Impressit’s ERP developers offer 
            high-quality ERP web application development and are ready to create a custom ERP system that would 
            meet your business’ needs and boost your employees’ productivity. Hire erp developers and take
            your first steps to success.
        `,
    },
    {
        title: "SaaS application development",
        iconPath: [WebDev5, null],
        description: `
            Interested in SaaS application development? No matter how difficult the problem you want to solve with 
            your SaaS product, the Impressit team’s SaaS application development services are here to solve your problems. 
            Our SaaS web application development company will create a sophisticated and efficient solution to satisfy 
            all the needs of your intended audience.
        `,
    },
    {
        title: "Enterprise software development",
        iconPath: [WebDev6, null],
        description: `
            Instead of struggling with research of what’s on the market, hire enterprise software developers with Impressit
            to develop a customized solution for your company. Impressit offers first-class custom enterprise software 
            development — we will create a flexible and elegant solution that would fit your needs precisely while 
            saving you time and money in perspective.
        `,
    },
];

const OffersSection = () => {
    const { isMobile } = useWindowDimensions();

    return (
        <>
            <OfferSectionStyle />
            <div className="offer-section__container">
                <DropDownListSection
                    sectionItems={listItems}
                    isMobile={isMobile}
                    heading="Impressit offers"
                    description="Whatever you need — the Impressit team is ready to assist"
                    headingMargin="40px"
                    itemsMargin="50px"
                />
            </div>
        </>
    );
};

export default OffersSection;
