import styled from "styled-components";
import { MOBILE_MIN_WIDTH, TABLET_MAX_WIDTH } from "@constants";

export const OffersSectionContainer = styled.div`
    & .benefits-section {
        margin: 160px 0 !important;

        &-items {
            grid-template-rows: none;
        }

        @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
            margin: 108px 0 !important;
        }

        @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
            margin: 80px 0 !important;
        }
    }

    & .benefits-section p {
        width: 100% !important;
    }

    & .page-heading__heading {
        text-align: center;

        @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
            text-align: start;
        }
    }

    & .benefits-card {
        height: 19.75rem;

        @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
            height: fit-content;
        }
    }
`;
